import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import { CiUser } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdLanguage } from "react-icons/md";
import { useLanguage } from "../../component/languageContext/LanguageContext";
import { languageOptions } from "../../utils/utitilyFunction";

const Header = () => {
  const { langCode, handleLanguageChange , hostname } = useLanguage();
  const currentLanguage = languageOptions[langCode];
  return (
    <Navbar bg="light" expand="lg" className="navClassHead py-3 shadow-lg">
      <Navbar.Brand className="navclassbrand">
        <img
          src="../../assets/static/logo.png"
          alt="logo"
          style={{ width: "60px", height: "30px" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className=" navclass">
          <NavDropdown
            title={
              <span>
                Advice <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="adviceDropdown"
          >
            <NavDropdown.Item>
              <Link to="/acting">Acting</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/modelling">Modelling</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/performers">Performers</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/photography">Photography</Link>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <span>
                Fashion <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="fashionDropdown"
          >
            <NavDropdown.Item>
              <Link to="/style-check">Style Check</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/match-it">Match It</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/footwear">Footwear</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/men">Men</Link>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <span>
                Beauty <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="beautyDropdown"
          >
            <NavDropdown.Item>
              <Link to="/facecare">Face Care</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/hair-care">Hair Care</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/make-up">Make Up</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/body-care">Body Care</Link>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <span>
                Accessories <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="accessoriesDropdown"
          >
            <NavDropdown.Item>
              <Link to="/jewelry">Jewelry</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/bags">Bags</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/watches">Watches</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/fragrances">Fragrances</Link>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <span>
                Fitness <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="fitnessDropdown"
          >
            <NavDropdown.Item>
              <Link to="/exercise">Exercise</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/nutrition">Nutrition</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/supplements">Supplements</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/emotions">Emotions</Link>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <span>
                Talk of Town <MdKeyboardArrowDown color="#CC1C1E" size={24} />
              </span>
            }
            id="talkOfTownDropdown"
          >
            <NavDropdown.Item>
              <Link to="/fashion-weeks">Fashion Weeks</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/rendevouz">Rendevouz</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/classics">Classics</Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/international">International</Link>
            </NavDropdown.Item>
          </NavDropdown>
          {
            hostname !== "takemyaudition.com" ? (<><NavDropdown
              title={
                <span>
                  <MdLanguage color="gray" size={25} />
                </span>
              }
              id="changeLanguageDropdown"
            >
              <NavDropdown.Item
                onClick={() => handleLanguageChange("en")} 
              >
                English
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleLanguageChange(langCode)}
              >
                {currentLanguage} 
              </NavDropdown.Item>
            </NavDropdown> </>) : (null) 
          }
        </Nav>
        <div className="d-flex flex-column flex-md-row align-items-start btngap ms-3">
          <Button
            as={Link}
            to="/your-target-path"
            className="post-audition-btn no-border  mb-md-0 "
            style={{
              backgroundColor: "#CC1C1E",
              borderRadius: "50px",
              fontWeight: "bold",
              border: "0",
              fontSize: "12px",
              padding: "10px 15px 10px 15px",
            }}
          >
            Post Audition Now
          </Button>

          <Link
            // to="/Login"
            style={{ textDecoration: "none", color: "inherit" }}
            className=" mt-2 mt-md-1 ml-md-3 mx-1"
          >
            <CiUser size="1em" /> Login / Register
          </Link>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
