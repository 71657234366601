import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CommonLayout from '../../../layout/commonlayout/commonlayout';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import useSignUpValidation from '../../../utils/useSignUpVlidation';
import TermsModal from '../../../modals/TermsModal';
// import './SignUp.css';

const SignUp = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [showModal , setShowModal] = useState(false);

    const { errors, validateSignUpForm } = useSignUpValidation();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleSignUp = (e) => {
        e.preventDefault();
        const isValid = validateSignUpForm({ name, username, email, password, phone,agreeTerms  });
        if (!isValid) return;

        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            alert('Account created successfully!');
        }, 2000);
    };
const handleOtp = () => {
    navigate('/Otp');
}

const handleModalShow = () => {
    setShowModal(true);
}

const handleModalClose = () => {
    setShowModal(false); 
};

    return (
        <CommonLayout>
            <div className="d-flex justify-content-center py-4 mb-2" style={{ background: '#ffffff' }}>
                <div
                    className="card shadow p-4 border-0"
                    style={{
                        maxWidth: '450px',
                        width: '100%',
                    }}
                >
                    <div className="mb-4">
                        <img
                            src="../../../assets/static/logo.png"
                            alt="Take My Audition Logo"
                            className="img-fluid mb-2"
                        />
                    </div>
                    <h5 className="fw-bold mb-3">Sign up</h5>
                    <form>
                        <div className="mb-3">
                            <input
                                type="text"
                                className={`form-control p-3 rounded-0 ${errors.name ? 'is-invalid' : ''}`}
                                placeholder="Your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                              {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className={`form-control p-3 rounded-0 ${errors.username ? 'is-invalid' : ''}`}
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type="email"
                                className={`form-control p-3 rounded-0 ${errors.email ? 'is-invalid' : ''}`}
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                        <div className="mb-3 ">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className={`form-control p-3 rounded-0 ${errors.password ? 'is-invalid' : ''}`}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                             {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            <span
                                onClick={togglePasswordVisibility}
                                className="position-absolute end-0 translate-middle-y pe-5 cursor-pointer pb-5"
                                style={{ cursor: 'pointer' }}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        <div className="mb-3 position-relative ">
                            <input
                                type="tel"
                                className={`form-control p-3 rounded-0 ${errors.phone ? 'is-invalid' : ''}`}
                                placeholder="+91"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}


                            />
                              {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            <button type="button" className="btn border-0 rounded-0 text-danger fw-bold neddlcass position-absolute top-50 end-0 translate-middle-y pe-4" onClick={handleOtp}>
                                {loading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                                ) : 'Send OTP'}
                            </button>

                        </div>
                        <button
                            type="button" onClick={handleSignUp}
                            className="btn w-100 text-white  rounded-0 btncolorclass mb-3"
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                            ) : 'Create your account'}
                        </button>  

                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="agreeTerms"
                                checked={agreeTerms}
                                onChange={(e) => setAgreeTerms(e.target.checked)}
                            />
                            <label className="form-check-label  neddclasswithcheck text-dark" htmlFor="agreeTerms">
                                By creating an account, you agree to our{' '}
                                <span
                                    className="text-decoration-none fw-bold"
                                    onClick={handleModalShow}
                                    style={{ cursor: 'pointer', color: '#007bff' }}
                                >
                                    terms & conditions
                                </span>
                            </label>
                            {errors.agreeTerms && <div className="invalid-feedback d-block">{errors.agreeTerms}</div>}
                        </div>
                        
                    </form>
                    <p className=" mt-2 text-muted neddclass">
                        Already have an account?
                    </p>
                    <button type='button' onClick={() => navigate('/Login')}
                        className="btn btn-outline-secondary w-100 fw-bold rounded-0 secondbtn"
                    >
                        Sign In
                    </button>
                </div>
            </div>

            <TermsModal show={showModal} handleClose={handleModalClose} />
        </CommonLayout>
    );
};

export default SignUp;
