import axios from 'axios';
import Cookies from 'js-cookie';

export const makeJsonApiRequest = async (
    method,
    endpoint,
    jsonData = null
  ) => {
    try {
      let headers = {
          'Content-Type': 'application/json',
        };

      const config = {
        method,
        url: endpoint,
        data: jsonData,
        headers,
      };
  
      const response = await axios(config);
  
      return response;
    } catch (error) {
      return error.response;
    }
  };



  export const makeJsonApiRequestToken = async (
    method,
    endpoint,
    jsonData = null,
    token = null
  ) => {
    try {
      let headers = {};
      const storedToken = token || Cookies.get('token');
  
      if (storedToken) {
        headers = {
          'Content-Type': 'application/json',
          Authorization: storedToken,
        };
      } else {
        headers = {
          'Content-Type': 'application/json',
        };
      }
  
      const config = {
        method,
        url: endpoint,
        data: jsonData,
        headers,
      };
  
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error('API Request Error:', error);
      return error.response ? error.response.data : { error: 'Request failed' };
    }
  };
  
  
  export const makeBearerJsonApiRequest = async (
      method,
      endpoint,
      jsonData = null,
      token
    ) => {
      try {
        let headers = {};
        const storedToken = token || Cookies.get('token');
      
        if (storedToken) {
          headers = {
            'Content-Type': 'application/json',
            Authorization: ` ${storedToken}`,
          };
        } else {
          headers = {
            'Content-Type': 'application/json',
          };
        }
    
        const config = {
          method,
          url: endpoint,
          data: jsonData,
          headers,
        };
    
        console.log("response",config);
        const response = await axios(config);
        
        return response.data;
        
      } catch (error) {
        console.error('API Request Error:', error);
        return error.response ? error.response.data : { error: 'Request failed' };
      }
    };
  
  
  export const makeJsonFormDataApiRequest = async (
    method,
    endpoint,
    jsonData = null,
    token
  ) => {
    try {
      let headers = {};
      const storedToken = token || Cookies.get('token');
  
      if (storedToken) {
        headers = {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${storedToken}`,
        };
      } else {
        headers = {
          'Content-Type': 'multipart/form-data',
        };
      }
  
      const config = {
        method,
        url: endpoint,
        data: jsonData,
        headers,
      };
  
      const response = await axios(config);
  
      return response;
    } catch (error) {
      return error.response;
    }
  };
  