import './App.css';
import AppRoutes from './routes/AppRoutes';
import useScrollToTop from './component/customHook/useScrollToTop';
function App() {

  const ScrollToTopWrapper = ({ children }) => {
    useScrollToTop();
    return children;
  };

  return (
    <div className='App'>
      <ScrollToTopWrapper>
        <AppRoutes/>
      </ScrollToTopWrapper>
    </div>
  );
}

export default App;
