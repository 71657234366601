import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Error404 = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center align-items-center text-center">
        <Col xs={12} md={6}>
          <img
            src='../../../assets/error/404-error.avif'
            alt="404 Not Found"
            style={{ width: "100%", maxWidth: "400px" }}
          />
        </Col>
        <Col xs={12} md={6} className="mt-4 mt-md-0">
          <h1 className="display-4 fw-bold text-danger">404</h1>
          <h2 className="mb-3"> Not Article Found</h2>
          <p className="text-muted">
            The article you’re looking for doesn’t exist. It might have
            been moved, deleted, or never created.
          </p>
          {/* <Button
            variant="secondary"
            className="mt-3 px-4 py-2"
            href="/"
            style={{
              borderRadius: "25px",
              fontWeight: "bold",
            }}
          >
            Go Back to Home
          </Button> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Error404;
