import React, { useState } from 'react'
import CommonLayout from '../../layout/commonlayout/commonlayout'
import './Login.css'
import { Link, useNavigate } from 'react-router-dom'
import useValidation from '../../utils/useValidation'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify'
import PasswordToggle from '../../utils/PasswordToggle'
const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const { errors, validateLoginForm } = useValidation();

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const isValid = validateLoginForm({ email, password });
    //     if (!isValid) return;

    //     setLoading(true); // Start loading spinner

    //     try {
    //         const response = await loginUser(email, password);
    //         console.log("response", response);

    //         if (response && response.data && response.data.token) {
    //             toast.success(response.message);
    //             navigate('/Dashboard');
    //         } else {
    //             toast.error(response.message);
    //         }
    //     } catch (error) {
    //         if (error.response) {
    //             toast.error(
    //                 error.response.data.message || 'Invalid email or password'
    //             );
    //         } else if (error.request) {
    //             toast.error('No response received, please try again');
    //         } else {
    //             console.error('An error occurred', error);
    //         }
    //     } finally{
    //         setLoading(false);
    //     }
    //   };
    return (

        <CommonLayout>
            <div
                className="d-flex justify-content-center py-4 mb-2" style={{ background: '#ffffff' }}
            >
                <div
                    className="card shadow p-4 border-0"
                    style={{
                        maxWidth: "450px",
                        width: "100%",
                    }}
                >
                    <div className=" mb-4">
                        <img
                            src="../../../assets/static/logo.png"
                            alt="Take My Audition Logo"
                            className="img-fluid mb-2"
                        />

                    </div>
                    <h5 className="fw-bold">Sign in</h5>
                    <form>
                        <div className="mb-3">
                            <input
                                type="email"
                                className={`form-control p-3 rounded-0 ${errors.email ? "is-invalid" : ""}`}
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                        <div className="mb-3">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className={`form-control p-3 rounded-0 ${errors.password ? "is-invalid" : ""}`}
                                id="password"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            <PasswordToggle
                                showPassword={showPassword}
                                togglePasswordVisibility={togglePasswordVisibility}
                            />
                        </div>
                        <div className="mb-3">

                            <span className='text-dark neddclass'> Need help signing in?</span>  <Link to='' className=""> <strong className='neddclass'>Click Here</strong>
                            </Link>
                        </div>
                        <button
                            type="button"
                            className="btn  w-100 text-white fw-bold rounded-0 btncolorclass" disabled={loading}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                            ) : 'Sign In'}
                        </button>
                    </form>

                    <div className="mt-3">
                        <div className="mb-3">

                            <span className='text-dark neddclass'>Don't have a TakeMyAudition account?</span>  <Link to='/ForgotPassword' className=""> <strong className='neddclass'>Forgot password ?</strong>
                            </Link>
                        </div>
                        <button type='button' onClick={() => navigate('/Signup')}
                            className="btn btn-outline-secondary w-100 fw-bold rounded-0 secondbtn"
                        >
                            Register Now
                        </button>
                    </div>
                </div>
            </div>
        </CommonLayout>

    )
}

export default Login
