
import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { useLanguage } from "../component/languageContext/LanguageContext";

// Map domains to language codes
const domainToLangMap = {
  "takemyaudition.com": "en",
  "takemyaudition.in": "hi",
  "takemyaudition.ae": "ar",
  "takemyaudition.me": "ar",
  "localhost":"en"
  // Add other domains and languages here
};

// const getLanguageFromDomain = (hostname) => {
//   return domainToLangMap[hostname] || "en"; // Default to English if not found
// };

// // Helper function to create a slug from the title
// const createSlug = (title) => {
//   return title.toLowerCase().replace(/\s+/g, "-").replace(/[^\w-]+/g, "");
// };

const MetaTags = ({ metaDescription = "", metaKeywords = "" ,title}) => {
  // const { title } = useParams(); // Assuming the title is passed in the URL
  // const [langCode, setLangCode] = useState(null);
  const [slug, setSlug] = useState("");

  // const {langCode,hostname} = useLanguage();

  useEffect(() => {

    // Generate slug dynamically
    if (title) {
      setSlug(title);
    }
  }, [title]);

  const baseUrl = "https://takemyaudition.com"; 
  const canonicalUrl = `${baseUrl}/article/${slug}`;
  const alternateUrls = Object.keys(domainToLangMap).map((domain) => {
    const code = domainToLangMap[domain];
    return { lang: code, url: `https://${domain}/article/${slug}` };
  });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Add alternate hreflang tags */}
      {alternateUrls.map(({ lang, url }) => (
        <link key={lang} rel="alternate" hreflang={lang} href={url} />
      ))}
    </Helmet>
  );
};

export default MetaTags;
