import { handleApiError } from "../utils/authHelper";
import { makeJsonApiRequest } from "./apiRequest";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_API_URL;

console.log(baseUrl,"this is base url");

export const fetchArticles = async (articleId,langCode) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/article-for-en?article_id=${articleId}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    } catch (error) {
        handleApiError(error);
    }
}
export const fetchArticlesById = async (langCode ,article_id,articleLanguage) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-article-by-id-for-translate?langCode=${langCode}&article_id=${article_id}&articleLanguage=${articleLanguage}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    } catch (error) {
        handleApiError(error);
    }
}


export const fetchArticleByTag = async (code,_id,currentPage) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-article-by-tag?langCode=${code}&tagId=${_id}&page=${currentPage}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 


export const fetchArticleBySubCategoryTitle = async (langCode,title,page) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-article-by-sub-category?langCode=${langCode}&subCategoryTitle=${title}&page=${page}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 


export const fetchAllArticlesByLangCode = async (code , page) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-articles?langCode=${code}&page=${page}`,
        )

        console.log(data,"this is dta from api getting by name");
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    }catch (error) {
        handleApiError(error);
    }
} 



export const fetchArticlesByName = async (articleName,langCode) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/article/${langCode}/${articleName}`,
        )

        console.log(data,"this is dta from api getting by name");
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        // else{
        //     toast.error(data.message);
        // }
    } catch (error) {
        handleApiError(error);
    }
}



export const fetchArticlesWithId = async (articleId,langCode) =>{
    try {
        const {status, data} = await makeJsonApiRequest(
            "GET",
            `${baseUrl}/show-article-by-id?_id=${articleId}&langCode=${langCode}`,
        )
        if(status === 200 && data.success){
            console.log(data,"this is dta from api");
            return data;
        }
        else{
            toast.error(data.message);
        }
    } catch (error) {
        handleApiError(error);
    }
}