
import { useState } from 'react';

const useSignUpValidation = () => {
    const [errors, setErrors] = useState({});

    const validateSignUpForm = ({ name, username, email, password, phone, agreeTerms  }) => {
        const newErrors = {};

        if (!name.trim()) newErrors.name = 'Name is required';
        if (!username.trim()) newErrors.username = 'Username is required';
        if (!email.trim()) newErrors.email = 'Email is required';
        else if (!/^[\w-.]+@[\w-]+\.[a-z]{2,7}$/i.test(email)) newErrors.email = 'Invalid email format';
        if (!password.trim()) newErrors.password = 'Password is required';
        else if (password.length < 6) newErrors.password = 'Password must be at least 6 characters';
        if (!phone.trim()) newErrors.phone = 'Phone number is required';
        else if (!/^\+?\d{10,15}$/.test(phone)) newErrors.phone = 'Invalid phone number';
        if (!agreeTerms) newErrors.agreeTerms = 'You must agree to the terms and conditions';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return { errors, validateSignUpForm };
};

export default useSignUpValidation
