// src/components/AdBanner.js
import React from 'react';
import { Container, Image  , Button} from 'react-bootstrap';
import {  MdKeyboardArrowLeft } from "react-icons/md";
import {Link} from 'react-router-dom';
import './adBanner.css';
const AdBanner = ({articles}) => {
console.log("articles from ad banneer",articles);
return(
  <Container className=" mx-0 p-3" style={{ width: '100%' }} fluid>
    <Link to ='/' style={{textDecoration:"none"}}>
    <button className="btn btn-link text-dark d-flex align-items-center banrmarginclass mb-2" style={{textDecoration:"none"}}>
    <MdKeyboardArrowLeft color="#CC1C1E" size={20} />
    <span className="ms-2">All Articles</span>
      </button>
    </Link>
    <Image src={articles?.banner?.bannerImage} alt="" className='img-fluid imagclassssss'/>
  </Container>
);
};

export default AdBanner;
