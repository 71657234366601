import React from "react";
import { Container, Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Recommenrdproduct.css";
import { responsive } from "../../utils/utitilyFunction";

const RecommendedProducts = () => {
  const products = [
    {
      id: 1,
      image: "../../../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    },
    {
      id: 2,
      image: "../../../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    },
    {
      id: 3,
      image: "../../../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    },
    {
      id: 4,
      image: "../../../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    },
    {
      id: 5,
      image: "../../../assets/static/sideAdd3.png",
      category: "footwear",
      title: "URBANBOX",
      description: "Trending Stylish Casual Outdoor Sneakers Shoes For Men ..."
    }
  ];

  return (
    <Container fluid className="my-3" >
      <div className="articleclass justify-content-between align-items-center mb-3 colclass">
        <div>
          <h3 className="fw-bold">Recommended Products</h3>
          <p>(we might earn commissions from qualifying purchases)</p>
        </div>
      </div>
      <Carousel responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition="all 0.5s"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
        {products.map((product) => (
          <div key={product.id} className="p-2">
            <Card
              className="shadowclass"
              style={{ borderRadius: "20px", width: "100%" }}
            >
              <Card.Img
                variant="top"
                src={product.image}
                className = 'recArcProImage'
                style={{ borderRadius: "20px 20px 0px 0px" }}
              />
              <Card.Body className="mt-0">
                <p
                  style={{
                    color: "#374151",
                    fontWeight: "600",
                    fontSize: "0.8rem",
                    background: "#f3eded",
                    borderRadius: "20px",
                    width: "34%",
                    padding: "5px",
                    marginBottom: "10px"
                  }}
                  className="text-center"
                >
                  {product.category}
                </p>
                <p className="fw-bold text-dark ms-2 mb-0">{product.title}</p>
                <p className="pclass">{product.description}</p>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default RecommendedProducts;
