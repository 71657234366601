import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { Input } from 'antd'
const FeedBack = () => {
  const { TextArea } = Input;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pageLink, setPageLink] = useState('');
  const [feedback, setFeedback] = useState('');
  const [randomNum1, setRandomNum1] = useState(Math.floor(Math.random() * 100) + 1);
  const [randomNum2, setRandomNum2] = useState(Math.floor(Math.random() * 100) + 1);
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate math answer
    if (parseInt(answer) === randomNum1 + randomNum2) {
      setSuccess(true);
      setError('');

      console.log('Feedback Submitted:', { name, email, feedback });
    } else {
      setSuccess(false);
      setError('Incorrect answer. Please try again.');
    }
  };

  return (
    <Container fluid className="pe-lg-5 pe-sm-0">
      <h5 className="fw-bold text-dark mb-2">Feedback</h5>
      <hr />
      <div className="mt-3 mb-5" style={{ textAlign: 'justify' }}>
        <p className="mb-4">
          TakeMyAudition.com is a leading Bollywood and auditions news website and voted as the best
          entertainment website. We are highly selective in our content and choose the most exciting and
          accurate information for our readers. All content, reviews, and news on TakeMyAudition.com are
          done by our panel of experts and journalists. If you find any error in our content or anything
          offending/objectionable on our website, please report it through the form below. We assure you that
          we will revise our content as soon as possible.
        </p>
        <p className="mb-4">
          We are committed to the safety of teenagers and young users. TakeMyAudition.com provides full
          support to our young talents to groom themselves and achieve their dreams. There have been
          instances where young talents fall prey to fake casting agents who exploited them for money and
          other favors. If you find any user making fake promises to you; bring that user to our notice. We
          deal with such cases on high priority and will take necessary action. We will also make sure to
          inform other users about such fake agents.
        </p>
        <p className="mb-4">
          Should you have any complaint or anything objectionable you would like to bring to our notice,
          please fill out the form and we will consider your suggestion within a few hours.
        </p>
      </div>

      {/* Feedback Form */}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <input
            type="text"
            className='form-control p-3 rounded-0'
            id="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <input
            type="email"
            className='form-control p-3 rounded-0'
            id="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <input
            type="text"
            className='form-control p-3 rounded-0'
            id="pageLink"
            placeholder="Page Link"
            value={pageLink}
            onChange={(e) => setPageLink(e.target.value)}
          />
        </Form.Group>


        <Form.Group className="mb-3">
          <TextArea className='rounded-0 textplce'
            rows={5}
            placeholder=" feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          />
        </Form.Group>

        {/* Math Captcha */}
        <div className="row align-items-center">
          {/* Random Numbers Column */}
          <div className="col-2">
            <Form.Label className="fw-bold">{randomNum1} + {randomNum2} =</Form.Label>
          </div>

     
          <div className="col-3">
            <input
              className="form-control p-3 rounded-0"
              type="number"
              placeholder="Answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
          </div>

       
          <div className="col-7">
            <button
              type="button"
              className="btn w-100 text-white fw-bold rounded-0 btncolorclass"
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ width: '1rem', height: '1rem' }}
                ></span>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </div>

        {/* Alerts for error/success */}
        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        {success && <Alert variant="success" className="mt-3">Feedback submitted successfully!</Alert>}

      </Form>
    </Container>
  );
};

export default FeedBack;
