// validation/useValidation.js
import { useState } from "react";

const useValidation = () => {
  const [errors, setErrors] = useState({});

  const validateLoginForm = (values) => {
    const validationErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!values.email) {
      validationErrors.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      validationErrors.email = "Invalid email format";
    }

    if (!values.password) {
      validationErrors.password = "Password is required";
    } else if (values.password.length < 6) {
      validationErrors.password = "Password must be at least 6 characters";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  return { errors, validateLoginForm };
};

export default useValidation;

// utils/validatePassword.js

export const validatePasswords = (newPassword, confirmPassword) => {
  let error = '';

  if (!newPassword || !confirmPassword) {
      error = 'Both password fields are required';
      return error;
  }

  if (newPassword !== confirmPassword) {
      error = 'Passwords do not match';
      return error;
  }

  if (newPassword.length < 6) {
      error = 'Password must be at least 6 characters';
      return error;
  }

  return error; 
};
