import React, { useEffect, useState } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { GrAttachment } from 'react-icons/gr';
import { useLanguage } from '../../component/languageContext/LanguageContext';

const SharLink = ({ articleUrl, title ,id }) => {
  const [shareTitle, setShareTitle] = useState('');
  const {hostname} = useLanguage();
  console.log(articleUrl,"helllooooooooooo");
  const domain = hostname ? `https://${hostname}/${shareTitle}/${articleUrl}/${id}` : `https://takemyaudition.com/${shareTitle}/${articleUrl}/${id}`;
  const   facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(domain)}`;
  const   whatsappShare = `https://api.whatsapp.com/send?text=${encodeURIComponent(`${title} - ${domain}`)}`;
  const copyLink = () => {
    navigator.clipboard.writeText(domain);
    alert('Link copied to clipboard!');
  };

  function createSlug(text) {
    if (typeof text === "string") {
      return text.toLowerCase().replace(/\s+/g, '-');
    }
    return ''; // Fallback for invalid input
  }
  
  useEffect(()=>{
    let slugTitle = createSlug(title);
    setShareTitle(title);
  },[articleUrl,title]);
  return (
    <Container className="">
      <Row className="">
        <div className="d-flex align-items-center">
          {/* Share Text */}
          <span style={{ color: '#626262', fontWeight: 'semibold' }} className="me-4">
            Share
          </span>
          {/* Facebook Share Button */}
          <Button
            href={facebookShare}
            target="_blank"
            className="m-2"
            style={{ background: 'transparent', border: 0, padding: 0 }}
          >
            <img
              src="../../../../../assets/static/facebook.webp"
              alt="facebook"
              style={{ width: '50px' }}
            />
          </Button>
          {/* WhatsApp Share Button */}
          <Button
            href={whatsappShare}
            target="_blank"
            className="m-2"
            style={{ background: 'transparent', border: 0, padding: 0 }}
          >
            <img
              src="../../../../../assets/static/whatsapp.png"
              alt="whatsapp"
              style={{ width: '30px' }}
            />
          </Button>
          {/* Copy Link Button */}
          <Button
            onClick={copyLink}
            className="m-2 ms-3"
            style={{
              borderRadius: '50%',
              border: '1px solid red',
              background: '#FCE9E9',
              padding: '4px 10px',
            }}
          >
            <GrAttachment color="red" size={15} />
          </Button>
        </div>
      </Row>
    </Container>
  );
};
export default SharLink;