import React, { useEffect, useState, useCallback } from "react";
import CommonLayout from "../../layout/commonlayout/commonlayout";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import MainContent from "../mainContent/MainContent";
import Comments from "../comments/Comments";
import Sidebar from "../sidebar/Sidebar";
import RecommendedProducts from "../recommendedProducts/RecommendedProducts";
import RecommendedArticles from "../recommendedArticle/RecommendedArticle";
import AdBanner from "../adBanner/AdBanner";
import { useLanguage } from "../../component/languageContext/LanguageContext";
import {
  fetchArticles,
  fetchArticlesById,
  fetchArticlesByName,
  fetchArticlesWithId,
} from "../../services/apiServices";
import MetaTags from "../../seo/seoConfig";
import SharLink from "../shareLink/SharLink";
import Error404 from "../../error/Error404";
import { useLocation, useParams } from "react-router-dom";

const MainComponent = () => {
  const { slug, language, id } = useParams();
  const location = useLocation();
  const { articleId, articleTitle, englishContent, articleLanguage } =
    location.state || {};
  const { langCode, choosedLanguage, englishCode, toggleLanguage } =
    useLanguage();

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [articleIdForChange, setArticleIdForChange] = useState(null);
  const [searchToggle, setsearchToggle] = useState(false);

  function reverseSlug(title) {
    if (typeof title === "string" && title.trim() !== "") {
      return title.toLowerCase().replace(/-/g, " ");
    }
    return ""; 
  }

  console.log(toggleLanguage,"this is toggle language");

  const fetchArticleData = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      let response;

      // Helper function to check if articleId is invalid
      const isInvalidArticleId = (ids) => {
        return (
          ids === null || // Check if null
          ids === undefined || // Check if undefined
          ids === "" || // Check if empty string
          (typeof ids === "object" && // Check if empty object
            Object.keys(ids).length === 0)
        );
      };

      if (
        !toggleLanguage &&
        choosedLanguage === "en" &&
        language === "en" &&
        !isInvalidArticleId(id)
      ) {
        response = await fetchArticlesWithId(id, language);
      } else if (
        toggleLanguage &&
        choosedLanguage !== "en" &&
        language === "en" &&
        !isInvalidArticleId(id)
      ) {
        response = await fetchArticlesById(choosedLanguage, id, language);
      } else if (
        toggleLanguage &&
        choosedLanguage !== "en" &&
        language !== "en" &&
        !isInvalidArticleId(id)
      ) {
        response = await fetchArticlesWithId(id, choosedLanguage);
      } else if (
        !toggleLanguage &&
        language !== "en" &&
        choosedLanguage === "en" &&
        !isInvalidArticleId(id)
      ) {
        response = await fetchArticlesById(
          choosedLanguage,
          id,
          articleLanguage
        );
      } 
      else {
        // Fallback to fetching article by slug when articleId is invalid
        // const originalSlug = reverseSlug(slug);
        // response = await fetchArticlesByName(originalSlug, langCode);
        // setsearchToggle(true);
      }
      if (response && response !== 404) {
        setArticles(
          Array.isArray(response.data) ? response.data[0] : response.data
        );
        setArticleIdForChange(
          response.data.languages !== "en"
            ? response?.data?.englishContent?._id
            : response?.data?._id
        );
      } else {
        setError(true);
      }
    } catch (err) {
      console.error("Error fetching articles:", err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [
    choosedLanguage,
    id,
    slug,
    englishCode,
    articleIdForChange,
    langCode,
  ]);

  useEffect(() => {
    fetchArticleData();
  }, [fetchArticleData]);

  if (error) {
    return (
      <CommonLayout>
        <Container fluid>
          <Row className="mx-2 p-3">
            <Col lg={9} className="order-lg-1 order-1">
              <Error404 />
            </Col>
            <Col lg={3} className="order-lg-2 order-2">
              <Sidebar />
            </Col>
          </Row>

          {/* <Error404 /> */}
        </Container>
      </CommonLayout>
    );
  }

  return (
    <CommonLayout>
      <MetaTags
        title={articles?.title}
        metaDescription={articles?.seo?.metaDescription}
        metaKeywords={articles?.seo?.metaKeywords}
      />

      <Container fluid className="p-0">
        <AdBanner articles={articles} />
        <SharLink
          articleUrl={language}
          title={slug}
          id={id}
        />

        {loading ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Row className="mx-2">
              <Col lg={9} className="order-lg-1 order-1">
                <MainContent articles={articles} />
                <Comments articles={articles} />
              </Col>
              <Col lg={3} className="order-lg-2 order-2">
                <Sidebar />
              </Col>
            </Row>

            <Row className="mx-2">
              <Col lg={12}>
                <RecommendedProducts articles={articles} />
              </Col>
            </Row>

            <Row className="mx-2">
              <Col lg={12}>
                <RecommendedArticles articles={articles} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </CommonLayout>
  );
};

export default MainComponent;
