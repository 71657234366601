import React, { useState } from 'react'
import CommonLayout from '../layout/commonlayout/commonlayout'
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy'
import FooterPageSidebar from './FooterPageSidebar'
import { Container , Row , Col , Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Error404 from '../error/Error404'
import FeedBack from '../pages/feedback/FeedBack'

const FooterCommonPageLayout = () => {
  const location = useLocation();
    const [loading , setLoading ]= useState(false);

    const renderContent = () => {
      switch (location.pathname){
        case '/PrivacyPolicy':
          return <PrivacyPolicy />
          case '/Feedback' :
            return <FeedBack />
            default :
            return <Error404/>
      }
    };
  return (
  <CommonLayout>
      <Container fluid className="p-0">
        {loading ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Row className="mx-lg-2 mx-sm-0 p-lg-5 p-sm-0">
              <Col lg={9} className="order-lg-1 order-1">
              {renderContent()}
              </Col>
              <Col lg={3} className="order-lg-2 order-2">
                <FooterPageSidebar />
              </Col>
            </Row>
          </>
        )}
      </Container>
  </CommonLayout>
  )
}

export default FooterCommonPageLayout
