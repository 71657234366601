
export const ROUTES = {
    COMMON: {
      RECOMMENDED_ARTICLES: "/",
      ARTICLES: "/:slug/:language/:id",
      TAG_ARTICLES: "/:tagName",
      CATEGORY_ARTICLES: "/:subCategory",
      NOT_FOUND: "*",
    },
    AUTH : {
      LOGIN: '/Login',
      SIGNUP:'/Signup',
      OTP :'/Otp',
      FORGOT_PASSWORD :'/ForgotPassword',
      RESET_PASSWORD :'/ResetPassword',
    },
    FOOTER:{
      PRIVACY_POLICY:"/PrivacyPolicy",
      FEEDBACK:'/Feedback',
    }
  };