import React, { useState } from 'react';
import CommonLayout from '../../../layout/commonlayout/commonlayout';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Otp = () => {
    const navigate = useNavigate();

    const [otp, setOtp] = useState(['', '', '', '']); 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Only allow numeric input
        if (value.match(/[^0-9]/)) return;

        // Update the OTP state
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // If the value is entered, move to the next input field
        if (value && index < 3) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (e, index) => {
        // If Backspace is pressed and the current input is empty, focus the previous input
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Check if all OTP digits are entered
        if (otp.some((digit) => digit === '')) {
            setError(true);
            toast.error('Please enter all 4 digits of the OTP');
            return;
        }

        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            toast.success('OTP verified successfully');
            navigate('/next-page'); 
        }, 2000);
    };

    return (
        <CommonLayout>
            <div className="d-flex justify-content-center py-4 mb-2" style={{ background: '#ffffff' }}>
                <div className="card shadow p-4 border-0" style={{ maxWidth: '450px', width: '100%' }}>
                    <div className="mb-4">
                        <img src="../../../assets/static/logo.png" alt="Take My Audition Logo" className="img-fluid mb-2" />
                    </div>
                    <h5 className="fw-bold mb-4">OTP Verification</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between mb-4 p-3">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    id={`otp-input-${index}`}
                                    className={`form-control p-2 text-center ${error && digit === '' ? 'is-invalid' : ''}`}
                                    value={digit}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    maxLength="1"
                                    autoFocus={index === 0}
                                    style={{ width: '50px' }}
                                />
                            ))}
                        </div>
                        {error && <div className="invalid-feedback d-block">Please enter all OTP digits.</div>}
                        <button
                            type="submit"
                            className="btn w-100 text-white fw-bold rounded-0 btncolorclass"
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                            ) : (
                                'Verify OTP'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </CommonLayout>
    );
};

export default Otp;
