import React, { useEffect } from "react";
import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import "./reacArcBanner.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../component/languageContext/LanguageContext";
import { formatStringType } from "../../../utils/utitilyFunction";

const RecArcBanner = ({ articlesData }) => {
const navigate = useNavigate();
  function createSlug(text) {
    return text.toLowerCase().replace(/\s+/g, '-');
  }
  
  const handleNavigate = (article) =>{
  
    const id = article?.languages !== "en" ? article.englishContent._id : article?._id;
    const language = article?.languages !== "en" ? article?.languages : "en";
    navigate(`/${createSlug(article?.title)}/${language}/${article?._id}`, { state: { articleId: article._id , articleTitle: article?.title, englishContent : id , articleLanguage: language} });
  }
  console.log(articlesData, "this is article data");

  const { langCode } = useLanguage();
  return (
    <Container
      className=" mb-5 recbanclass p-0"
      style={{ width: "100%" }}
      fluid
    >
      {articlesData.map((article, index) => {
        // Check if `bannerData` or `banner` exists safely
        const bannerDataImage =
          article.bannerData && article.bannerData[0]?.bannerImage;
        const bannerImage = article.banner?.bannerImage;

        // Use `bannerDataImage` if available; fallback to `bannerImage`
        const imageUrl = bannerDataImage || bannerImage || null;

        return (
          <div key={index} className="mb-4">
            {imageUrl && (
              <div
                className="background-image mb-3"
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  height: "200px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                }}
              />
            )}
            <Row>
              <Col>
                <span className="text-danger fw-bold d-flex align-items-center g-10 mb-2">
                  <FaPlus />
                  {article?.subCategory?.name?.en} 
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9}>
                  <Button onClick={() => handleNavigate(article)} style={{background:'transparent' }} className="border-0 text-dark p-lg-0 p-sm-0">
                    <span className="sizeclass mb-2 fw-bold">{article?.title}</span>
                  </Button>
               
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <p>{article?.shortDescription}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} className="d-flex justify-content-end mb-3">
                  <Button
                    className="post-audition-btn no-border mb-md-0"
                    onClick={() => handleNavigate(article)}
                    style={{
                      backgroundColor: "#CC1C1E",
                      borderRadius: "50px",
                      border: "0",
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      padding: "8px 18px",
                    }}
                  >
                    Read More <HiOutlineArrowNarrowRight size={25} />
                  </Button>
                
              </Col>
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default RecArcBanner;
