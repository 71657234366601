import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonlayout/commonlayout";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import Sidebar from "../sidebar/Sidebar";
import { MdArticle } from "react-icons/md";
import RecArcBanner from "../recArticleLayout/recArcBanner/RecArcBanner";
import RecSidebar from "../recArticleLayout/recSidebar/RecSidebar";
import { MdLanguage } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchArticleByTag,
  fetchAllArticlesByLangCode,
} from "../../services/apiServices";
import Error404   from "../../error/Error404";
import { useLanguage } from "../../component/languageContext/LanguageContext";
import "./RecomArticlPage.css";
const RecomendedArticlePages = () => {
  const { langCode , choosedLanguage } = useLanguage();
  const [articlesData, setArticlesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { tagName} = useParams();
  const location = useLocation();
  const tagId = location.state;
  const [loading, setLoading] = useState(false);
  const [error , setError] = useState(false);
  const fetchArticlesData = async () => {
    setLoading(true);
    setError(false);
    try {
      let response;
      if (tagName && tagId) {
        response = await fetchArticleByTag(langCode, tagId, currentPage);
      } else {
        response = await fetchAllArticlesByLangCode(choosedLanguage, currentPage);
      }

      if (response && response.status !== 404) {
        setArticlesData((prevArticles) => [
          ...prevArticles,
          ...response.data.articles,
        ]);
        setTotalPages(response.data.totalPage);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

   useEffect(()=>{
        setArticlesData([]);
        setCurrentPage(1);
        setTotalPages(0);
      },[choosedLanguage]);

  useEffect(() => {
    if (langCode) {
      fetchArticlesData();
    }
  }, [langCode, currentPage , choosedLanguage]);

  const loadMoreArticles = () => {
    if (currentPage < totalPages) {
      setLoading(true);
      setTimeout(() => {
        setCurrentPage((prevPage) => prevPage + 1);
        setLoading(false);
      }, 500);
    }
  };

  if(error){
    return (
      <CommonLayout>
        <Container fluid>
          <Row className="mx-lg-2 p-lg-3 mx-sm-0 p-sm-0">
            <Col lg={9} className="order-lg-1 order-1">
              <Error404 />
            </Col>
            <Col lg={3} className="order-lg-2 order-2">
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </CommonLayout>
    );
  }

 
  return (
    <div>
    <CommonLayout>
      <Container fluid>
        <Row className="classContainer">
          <Col xs={12} md={8}>
            <div className="latstclass">
              <h5 className="fw-bold d-flex align-items-center">
                <MdArticle /> Latest Articles
              </h5>
              <hr></hr>
            </div>
          </Col>
          <Col xs={12} md={4} className="secondclass">
            <div className="mt-5 mb-1 ms-5">
              <h5 className="fw-bold d-flex align-items-center">
                <MdLanguage /> Pages to follow
              </h5>
              <hr></hr>
            </div>
          </Col>
        </Row>

        {loading && currentPage === 1 ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status" variant="danger">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Row className="mx-2">
              {/* Main content */}
              <Col lg={8} className="order-lg-1 order-1 banerclass">
                <div className="mainclass">
                  {error ? <Error404 /> : <RecArcBanner articlesData={articlesData} />}
                </div>
                {/* "Load More" button for small screens */}
                {currentPage < totalPages && (
                  <div className="text-center my-4 d-lg-none">
                    <Button
                      onClick={loadMoreArticles}
                      disabled={loading}
                      className={`load-more-btn ${loading ? "loading" : ""}`}
                      style={{
                        backgroundColor: "#CC1C1E",
                        borderRadius: "50px",
                        fontWeight: "bold",
                        border: "0",
                        fontSize: "17px",
                        padding: "10px 15px",
                      }}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Load More"
                      )}
                    </Button>
                  </div>
                )}
              </Col>

              {/* Sidebar */}
              <Col lg={3} className="order-lg-2 order-2 recsideclass">
                <RecSidebar />
              </Col>
            </Row>

            {/* "Load More" button for larger screens */}
            {currentPage < totalPages && (
              <div className="text-center my-4 d-none d-lg-block">
                <Button
                  onClick={loadMoreArticles}
                  disabled={loading}
                  className={`load-more-btn ${loading ? "loading" : ""}`}
                  style={{
                    backgroundColor: "#CC1C1E",
                    borderRadius: "50px",
                    fontWeight: "bold",
                    border: "0",
                    fontSize: "17px",
                    padding: "10px 15px",
                  }}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Load More"
                  )}
                </Button>
              </div>
            )}
          </>
        )}
      </Container>
    </CommonLayout>
  </div>
  );
};

export default RecomendedArticlePages;

// const fetchAllArticles = async () => {
//   try {
//     const response = await fetchAllArticlesByLangCode(langCode, currentPage);
//     if (response) {
//       // Append new articles to existing ones
//       setArticlesData(prevArticles => [...prevArticles, ...response.data.articles]);

//       // Check if we have more articles to load
//       if (response.data.articles.length < 10) {
//         setHasMore(false); // No more articles
//       } else {
//         setCurrentPage(response.totalPage); // Update current page
//       }
//     }
//   } catch (error) {
//     console.error("Error fetching articles:", error);
//   }
// }
