import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const PasswordToggle = ({ showPassword, togglePasswordVisibility }) => {
  return (
    <span
      onClick={togglePasswordVisibility}
      className="position-absolute top-50 end-0 translate-middle-y pe-5 pb-3 cursor-pointer"
      style={{ cursor: 'pointer' }}
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </span>
  );
};

export default PasswordToggle;
