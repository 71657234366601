import React, { createContext, useState, useContext, useEffect } from "react";

// Create Language Context
const LanguageContext = createContext();

// Language Provider
export const LanguageProvider = ({ children }) => {
  // const [language, setLanguage] = useState("en");

  const [langCode, setLangCode] = useState(null);
  const [englishCode,setEnglishCode] = useState("en");
  const [hostname,setHostname] = useState(null);
  const [toggleLanguage,setToggleLanguage] = useState(false);
  const [choosedLanguage, setChosenLanguage] = useState(localStorage.getItem("selectedLanguage"));
  // Map domains to language codes
const domainToLangMap = {
  "takemyaudition.com": "en",
  "takemyaudition.in": "hi",
  "takemyaudition.ae": "ar",
  "takemyaudition.me": "ar",
  "localhost": "en",
};

const getLanguageFromDomain = (hostname) => {
  return domainToLangMap[hostname] ; // Default to English if not found
};


const handleLanguageChange = (lang) => {
  setChosenLanguage(lang);
  localStorage.setItem("selectedLanguage", lang);
  setToggleLanguage(!toggleLanguage);
}

useEffect(() => {
  const host = window.location.hostname;
  setHostname(host);
  const detectedLangCode = getLanguageFromDomain(host); 
  setLangCode(detectedLangCode);
  const storedLanguage = localStorage.getItem("selectedLanguage");
  if (!storedLanguage) {
    setChosenLanguage("en");
    localStorage.setItem("selectedLanguage", "en");
  } else {
    setChosenLanguage(storedLanguage);
  }
}, []);

  return (
    <LanguageContext.Provider value={{langCode, hostname, choosedLanguage, handleLanguageChange, englishCode ,toggleLanguage}}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
