import React from 'react'
import { Container } from 'react-bootstrap'
const PrivacyPolicy = () => {
  return (
 <Container fluid className='pe-lg-5 pe-sm-0'>
  <h5 className='fw-bold text-dark mb-2'>Privacy Policy</h5>
  <span className='text-dark fw-bold neddclass'>Your privacy is important to us.</span>
<hr/>
  <div className='mt-3  mb-5' style={{textAlign:"justify"}}>
    <p className='mb-4 neddclass'>We have prepared this Privacy Policy to explain to you how we collect, use, and share information we obtain through your use of the sites. WWW.TAKEMYAUDITION.COM is an independent media company owned by the Maruti Web Services.</p>

    <p className='neddclass mb-4'>This Privacy Policy does not govern our use of any information you provide to us when you call us, write to us, or communicate with us in any manner other than through the Services. Because the Internet is global, information about you that we collect or that you submit, in each case in connection with your use of the Services, may be transferred to, processed in, and held in countries other than the one in which you reside. By using the Services, you explicitly consent to such use of your information and agree to the terms of this Privacy Policy.</p>

    <p className='neddclass mb-4'>
    The personal information which we collect from you through contact us forms, emails, complaints etc that can help us in identifying you as a specific individual are kept confidential. We also collect Non-personal information about you for our analytics and softwares. "Non-Personal Information" refers to information that, by itself, does not identify you as a specific individual (e.g., demographic information or website visits). Generally, Except as otherwise described in this section of our Privacy Policy, we will never share your Usage Data, Personal Information, Non-Personal Information, or Shared Information (which we will refer to in this Privacy Policy collectively as "User Information") with any third party without your permission.
    </p>

    <p className='neddclass mb-4'>If you contact WWW.TAKEMYAUDITION.COM by telephone, e-mail, or letter, WWW.TAKEMYAUDITION.COM may keep a record of your contact information and correspondence. If you report a problem with the website, WWW.TAKEMYAUDITION.COM may collect this information in a file specific to you. You may contact WWW.TAKEMYAUDITION.COM to request the removal of this information from WWW.TAKEMYAUDITION.COM database.</p>
    <p className='neddclass mb-4'>From time to time, we may share your Usage Data and Non-Personal Information to enable third parties who serve advertisements on the Services to deliver advertisements that will be relevant to you. We will not, however, share any such information in a manner that would enable the advertiser to personally identify you. You can learn more about the advertising companies and what options they may offer you regarding cookies and targeted advertising by reviewing the Network Advertising Initiative's Consumer Opt-Out directory. Many of these companies are also members of the Network Advertising Initiative or the Digital Advertising Alliance, which each provide a simple way to opt out of ad targeting from participating companies.</p>

    <p className='neddclass mb-4'>If you identify any User Information as public, you are authorizing us to share such information publicly. For example, you may elect to make certain Shared Information (such as your alias, bio, e- mail or photos) publicly available. Also, there may be areas of the Services (e.g., message boards, discussion rooms, and other digital forums) in which you are able to post information that will be available to all other users of the Services. By choosing to use these areas, you understand and agree that anyone may access, use, and disclose any information that you post to them. We are not responsible for the use or misuse by others of any such information.</p>

    <p className='neddclass mb-4'>The WWW.TAKEMYAUDITION.COM may contain links to third parties who may collect Personal Information and Non-Personal Information directly from you. Additionally, WWW.TAKEMYAUDITION.COM may use third parties to provide components of the WWW.TAKEMYAUDITION.COM. In either case, such third parties may have separate privacy policies and data collection practices, independent of WWW.TAKEMYAUDITION.COM WWW.TAKEMYAUDITION.COM: (a) has no responsibility or liability for these independent policies or actions; (b) is not responsible for the privacy practices or the content of such websites; and (c) does not make any warranties or representations about the contents, products, or services offered on such websites or the security of any information you provide to them.</p>

    </div>
    <h5 className='fw-bold text-dark mb-3'>Children's Privacy</h5>
    <span className='text-dark fw-bold neddclass'>We are committed to protecting the privacy needs of children, and we encourage parents and guardians to take an active role in their children's online activities and interests. The Services are not intended for and may not be used by children under the age of 13. We do not knowingly collect information from children under the age of 13, and we do not market the Services to children under the age of 13.</span>

    <div className='mt-3  mb-5' style={{textAlign:"justify"}}>
        <p className='mb-4 neddclass'>
        Our Services and our business may change from time to time. As a result, at times it may be necessary for WWW.TAKEMYAUDITION.COM to make changes to this Privacy Policy. WWW.TAKEMYAUDITION.COM reserves the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Please review this policy periodically, and especially before you provide additional Personal Information. Your continued use of the Service after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of the revised Privacy Policy.
        </p>

        <p className='neddclass mb-4'>
        WWW.TAKEMYAUDITION.COM do not represent or warrant that the Services, or any part thereof, are appropriate or available for use in any particular jurisdiction. Those who choose to access the Services do so on their own initiative and at their own risk, and are responsible for complying with all local laws, rules, and regulations. We may limit the Services' availability, in whole or in part, to any person, geographic area, or jurisdiction we choose, at any time and at our sole discretion. By using the Services and submitting any Personal Information, you consent to the transfer of Personal Information to other countries which may provide a different level of data security from your country of residence.
        </p>

        <p className='neddclass'>You may reach us at WWW.TAKEMYAUDITION.COM, info@takemyaudition.com</p>
        </div>
 </Container>
  )
}

export default PrivacyPolicy
