import React from 'react';
import { Container, Card } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive } from '../../utils/utitilyFunction';
const RecommendedArticles = ({ articles }) => {
  const relatedArticles = articles?.relatedArticles || [];
  return (
    <Container className="my-3" fluid>
      <div className="articleclass justify-content-between align-items-center mb-3 colclass">
        <div>
          <h3 className="fw-bold">Recommended Articles</h3>
        </div>
      </div>
      {relatedArticles?.length > 0 ? (
      <Carousel responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition="all 0.5s"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px">
           {relatedArticles?.map((product) => (
          <div key={product.id} className="p-2">
            <Card className="mb-3 shadowclass" style={{ borderRadius: "20px" }}>
              <Card.Img
                variant="top"
                 className = 'recArcProImage'
                src={product?.banner?.bannerImage}
                style={{ borderRadius: "20px 20px 0px 0px" }}
              />
              <Card.Body className="mt-0">
                <p
                  style={{
                    color: "#CC1C1E",
                    fontWeight: "600",
                    fontSize: "0.8rem",

                    marginBottom: "10px"
                  }}
                  className="text-start ms-2"
                >
                  {product?.category?.title}
                </p>
                <p className="fw-bold text-dark ms-2 mb-0">{product?.title}</p>
                <p className="pclass">{product?.shortDescription}</p>
              </Card.Body>
            </Card>
          </div>
        ))}
        
      </Carousel>
      ) : (
        <p className='fw-bold'>No recommended articles available.</p>
      )}
    </Container>
  );
};

export default RecommendedArticles;
