import React from 'react';
import './FooterPageSidebar.css';
import { AiOutlineStock } from "react-icons/ai"

const FooterPageSidebar = () => {
    const profiles = [
        { id: 1, name: 'Harsh Rami', age: 21, city: 'Mumbai', image: '../../../assets/static/sideAd1.png' },
        { id: 2, name: 'Nitin Sen', age: 25, city: 'Delhi', image: '../../../assets/static/sideAd1.png' },
        { id: 3, name: 'Aditi Sharma', age: 28, city: 'Bangalore', image: '../../../assets/static/sideAd1.png' },
        { id: 4, name: 'Rohit Mehta', age: 22, city: 'Pune', image: '../../../assets/static/sideAd1.png' },
    ];

    return (
        <>
        <div className='d-flex  align-items-center '>
        <AiOutlineStock size={25} className='me-2'/>
            <h5 className='fw-bold text-dark'>Featured Users</h5>
        </div>
        <hr/>
        <div className="d-flex flex-column align-items-center mt-4">
            {profiles.map((profile) => (
                <div className="card shadow card-container mb-3 border-0" key={profile.id}>
                    <div className="row g-0">
                        <div className="col-md-3 image-container">
                            <img
                                src={profile.image}
                                alt={profile.name}
                                className="img-fluid h-100"
                            />
                        </div>
                        <div className="col-md-9">
                            <div className="card-body">
                                <h6 className="card-title mb-2 fw-bold">{profile.name}</h6>
                                <p className="card-text mb-1">
                               <span className='neddclass'>Age</span> <span className="float-end fw-bold neddclass">{profile.age}</span>
                                </p>
                                <p className="card-text">
                                 <span className='neddclass'>Present City</span>
                                    <span className="float-end fw-bold neddclass">{profile.city}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

export default FooterPageSidebar;
