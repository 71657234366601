import  _ from "lodash";


export const formatStringType = (input, type = "startCase") => {
    if (!input) return "";
    // Transform input based on the provided type
    switch (type) {
      case "startCase":
        return _.startCase(_.toLower(input)); // Converts 'apna-bana-le' to 'Apna Bana Le'
      case "kebabCase":
        return _.kebabCase(input); // Converts 'Apna Bana Le' to 'apna-bana-le'
      case "camelCase":
        return _.camelCase(input); // Converts 'Apna Bana Le' to 'apnaBanaLe'
      case "snakeCase":
        return _.snakeCase(input); // Converts 'Apna Bana Le' to 'apna_bana_le'
      case "lowerCase":
        return _.toLower(input); // Converts 'Apna Bana Le' to 'apna bana le'
      case "upperCase":
        return _.toUpper(input); // Converts 'Apna Bana Le' to 'APNA BANA LE'
      default:
        return input; // Return the original if no matching case
    }
  };



  export const getLanguageCodeFromSubdomain = () => {
    const currentHost = window.location.hostname;
    const subdomain = currentHost.split('.')[0]; 
    return subdomain || 'en'; 
  };
   //http://example.en.com/article/articleaname


   export  const languageOptions = {
    ar: "Arabic",
    de: "German",
    ru: "Russian",
    hi: "Hindi",
    es: "Spanish",
    fr: "French",
    ur: "Urdu",
    tl: "Tagalog",
    ja: "Japanese",
    ko: "Korean",
    tr: "Turkish",
  };



  export const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, i) => (i % size === 0 ? [...acc, arr.slice(i, i + size)] : acc), []);
  };

 export  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };



export  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/-/g, "_") 
      .replace(/\s+/g, " ") 
      .replace(/[^\w\s]+/g, ""); 
};