import React, { useState } from 'react';
import CommonLayout from '../../../layout/commonlayout/commonlayout';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleNewPassword = () => {
    setShowNewPassword(prevState => !prevState);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(prevState => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!newPassword || !confirmPassword) {
      setError('Both fields are required');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Handle password reset logic here
    setError('');
    alert('Password has been reset successfully!');
  };

  return (
    <CommonLayout>
      <div className="d-flex justify-content-center py-4 mb-2" style={{ background: '#ffffff' }}>
        <div className="card shadow p-4 border-0" style={{ maxWidth: '450px', width: '100%' }}>
          <div className="mb-4">
            <img
              src="../../../assets/static/logo.png"
              alt="Take My Audition Logo"
              className="img-fluid mb-2"
            />
          </div>
          <h5 className="fw-bold mb-4">Reset Password</h5>
          <form>
            <div className="mb-3 position-relative">
              <input
                type={showNewPassword ? 'text' : 'password'}
                className={`form-control p-3 rounded-0 ${error && !newPassword ? 'is-invalid' : ''}`}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {error && !newPassword && <div className="invalid-feedback">New Password is required</div>}
              <span
                onClick={toggleNewPassword}
                className="position-absolute top-50 end-0 translate-middle-y pe-4 cursor-pointer"
                style={{ cursor: 'pointer' }}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="mb-4 position-relative">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className={`form-control p-3 rounded-0 ${error && !confirmPassword ? 'is-invalid' : ''}`}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {error && !confirmPassword && <div className="invalid-feedback">Confirm Password is required</div>}
              <span
                onClick={toggleConfirmPassword}
                className="position-absolute top-50 end-0 translate-middle-y pe-4 cursor-pointer"
                style={{ cursor: 'pointer' }}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              {error && newPassword !== confirmPassword && <div className="invalid-feedback">Passwords do not match</div>}
            </div>

            <button
              type="button"
              className="btn w-100 text-white fw-bold rounded-0 btncolorclass"
            >
            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                            ) : (
                                'Submit'
                            )}
            </button>
          </form>
        </div>
      </div>
    </CommonLayout>
  );
};

export default ResetPassword;
