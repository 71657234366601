import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonlayout/commonlayout";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { MdArticle } from "react-icons/md";
import RecArcBanner from "../recArticleLayout/recArcBanner/RecArcBanner";
import RecSidebar from "../recArticleLayout/recSidebar/RecSidebar";
import { MdLanguage } from "react-icons/md";
import { useParams } from "react-router-dom";
import { fetchArticleBySubCategoryTitle,} from "../../services/apiServices";
import { useLanguage } from "../../component/languageContext/LanguageContext";
import "../recommendedArticlesPage/RecomendedArticlePages";
import MetaTags from "../../seo/seoConfig";
import {metaData} from "../../utils/meta";
import {createSlug} from "../../utils/utitilyFunction";
import Error404 from "../../error/Error404";
import Sidebar from "../sidebar/Sidebar";
import './subCategory.css';


const SubCategoryArticle = () => {
 

  const { langCode , choosedLanguage , englishCode } = useLanguage();
  const [articlesData, setArticlesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); 
  const { subCategory} = useParams();
  const [meta, setMeta] = useState({
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false); 

   const fetchArticlesData = async () => {
      setLoading(true);
      setError(false);
      try {   
        const   response = await fetchArticleBySubCategoryTitle(choosedLanguage, subCategory, currentPage);
        if (response && response.status !== 404) {
          setArticlesData((prevArticles) => [
            ...prevArticles,
            ...response.data.articles,
          ]);
          setTotalPages(response.data.totalPages);
        }else {
          setError(true);
        }
      } catch (error) {
        console.error("Error fetching articles:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    useEffect(()=>{
      setArticlesData([]);
      setCurrentPage(1);
      setTotalPages(0);
      setError(false);
    },[choosedLanguage]);
  
    useEffect(() => {
      if (langCode) {
        fetchArticlesData();
      }
    }, [langCode, currentPage , choosedLanguage ,subCategory]);
  
    const loadMoreArticles = () => {
      if (currentPage < totalPages) {
        setLoading(true);
        setTimeout(() => {
          setCurrentPage((prevPage) => prevPage + 1);
          setLoading(false);
        }, 500);
      }
    };

    useEffect(() => {
      const subCategoryString = createSlug(subCategory);
      setMeta((prevMeta) => ({
        ...prevMeta,
        metaTitle: metaData.metaTitle[subCategoryString],
        metaDescription: metaData.metaDescription[subCategoryString], 
        metaKeywords: metaData.metaKeywords[subCategoryString], 
      }));
    }, [subCategory]);


    if(error){
      return (
        <CommonLayout>
          <Container fluid>
          <Row className="custommargin">
              <Col lg={9} className="order-lg-1 order-1">
                <Error404 />
              </Col>
              <Col lg={3} className="order-lg-2 order-2">
                <Sidebar />
              </Col>
            </Row>
  
            {/* <Error404 /> */}
          </Container>
        </CommonLayout>
      );
    }
  return (
    <div>
      <CommonLayout>
      <MetaTags
        title={meta?.metaTitle}
        metaDescription={meta?.metaDescription}
        metaKeywords={meta?.metaKeywords}
      />
        <Container fluid>
          <Row className="classContainer">
            <Col xs={12} md={8}>
              <div className="latstclass">
                <h5 className="fw-bold d-flex align-items-center">
                  <MdArticle /> Latest Articles
                </h5>
                <hr></hr>
              </div>
            </Col>
            <Col xs={12} md={4} className="secondclass">
              <div className="mt-5 mb-1 ms-5">
                <h5 className="fw-bold d-flex align-items-center">
                  <MdLanguage /> Pages to follow
                </h5>
                <hr></hr>
              </div>
            </Col>
          </Row>

          {loading && currentPage === 1 ? (
            <div className="text-center my-4">
              <Spinner animation="border" role="status" variant="danger">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <Row className="mx-lg-2 mx-sm-0">
                <Col lg={8} className="order-lg-1 order-1  banerclass">
                  <div className="mainclass">
                  {error ? (
                      <Error404 />
                    ) : (
                      <RecArcBanner articlesData={articlesData} />
                    )}
                   
                  </div>
                </Col>
                <Col lg={3} className="order-lg-2 order-2 recsideclass ">
                  <RecSidebar />
                </Col>
              </Row>
              {currentPage < totalPages && (
                <div className="text-center my-4">
                  <Button
                    onClick={loadMoreArticles}
                    disabled={loading}
                    className={`load-more-btn ${loading ? "loading" : ""}`}
                    style={{
                      backgroundColor: "#CC1C1E",
                      borderRadius: "50px",
                      fontWeight: "bold",
                      border: "0",
                      fontSize: "17px",
                      padding: "10px 15px 10px 15px",
                    }}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Load More"
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </CommonLayout>
    </div>
  );
};

export default SubCategoryArticle;
