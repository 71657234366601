import React, { useState } from 'react';
import CommonLayout from '../../../layout/commonlayout/commonlayout';
import { useNavigate , Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import { validatePasswords } from '../../../utils/useValidation';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showNewPassword, setNewShowPassword] = useState(false);
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);

    const toggleNewPassword = () => {
        setNewShowPassword(prevState => !prevState);
    };

    const toggleConfirmPassword = () => {
        setConfirmShowPassword(prevState => !prevState);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const validationError = validatePasswords(newPassword, confirmPassword);
        if (validationError) {
            setError(validationError);
            return;
        }

        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            toast.success('Password changed successfully');
            navigate('/login'); 
        }, 2000);
    };

    const handleNavigation = () => {
        navigate('/ResetPassword')
    }

    return (
        <CommonLayout>
            <div className="d-flex justify-content-center py-4 mb-2" style={{ background: '#ffffff' }}>
                <div className="card shadow p-4 border-0" style={{ maxWidth: '450px', width: '100%' }}>
                    <div className="mb-4">
                        <img
                            src="../../../assets/static/logo.png"
                            alt="Take My Audition Logo"
                            className="img-fluid mb-2"
                        />
                    </div>
                    <h5 className="fw-bold mb-4">Forgot Password?</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input
                                type={showNewPassword ? 'text' : 'password'}
                                className={`form-control p-3 rounded-0 ${error && !newPassword ? 'is-invalid' : ''}`}
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            {error && !newPassword && <div className="invalid-feedback">New Password is required</div>}
                            <span
                                onClick={toggleNewPassword}
                                className="position-absolute end-0 translate-middle-y pe-5 cursor-pointer pb-5"
                                style={{ cursor: 'pointer' }}
                            >
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        <div className="mb-4">
                            <input
                               type={showConfirmPassword ? 'text' : 'password'}
                                className={`form-control p-3 rounded-0 ${error && !confirmPassword ? 'is-invalid' : ''}`}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {error && !confirmPassword && <div className="invalid-feedback">Confirm Password is required</div>}
                            <span
                                onClick={toggleConfirmPassword}
                                className="position-absolute end-0 translate-middle-y pe-5 cursor-pointer pb-5"
                                style={{ cursor: 'pointer' }}
                            >
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            {error && newPassword !== confirmPassword && <div className="invalid-feedback">Passwords do not match</div>}
                        </div>

                         <div className="mb-3">
                        
                                                    <span className='text-dark neddclass'>Back to </span>  <Link to='/Login' className=""> <strong className='neddclass'>login</strong>
                                                    </Link>
                                                </div>

                        <button
                            type="button"
                            className="btn w-100 text-white fw-bold rounded-0 btncolorclass"
                            disabled={loading}
                            onClick={handleNavigation}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </CommonLayout>
    );
};

export default ForgotPassword;
