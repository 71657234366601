import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MidContent from "../midContent/MidContent";
import { Link } from "react-router-dom";
import "./MainContent.css";
import { useLanguage } from "../../component/languageContext/LanguageContext";
const MainContent = ({ articles }) => {
  console.log(articles, "this is articles");
  const { language } = useLanguage();
  return (
    <Container className="my-3 paddingclass">
      {/* Header with responsiveness */}
      <Row>
        <Col xs={12} md={9}>
          <h1 className="sizeclass mb-4">{articles?.title}</h1>
        </Col>
      </Row>

      {/* Short Description Section */}
      <Row>
        <Col xs={12} md={11}>
          <div
            className="p-3 widthClass"
            style={{ backgroundColor: "#FFE0E1", borderRadius: "8px" }}
          >
            <p
              className="lead marginclass m-0 p-1"
              style={{ fontSize: "1rem", fontWeight: "500" }}
            >
              {articles?.shortDescription}
            </p>
          </div>
        </Col>
      </Row>

      {/* MidContent Section */}
      <MidContent articles={articles} />

      {/* Main Content Section */}
      <Row>
        <Col xs={12} md={11}>
          <div
            className="newwwwwwwwwwww"
            dangerouslySetInnerHTML={{ __html: articles?.content }}
          />
          {/* <p className="justified-text">{articles?.content.en}</p> */}
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={12}>
          {articles?.tags?.map((tag, index) => (
            <div className="d-flex">
              <Link to={`/article/tag/${tag?.title?.en}`}
              state={tag?._id}
              >
              <span
                key={index}
                className="badge bg-danger text-white me-2 mb-2 badgeclass"
              >
                #{tag?.title?.en}
              </span>
              </Link>

              <Link to={`/article/tag/${tag?.title?.secondary}`}
              state={tag?._id}
              >
              <span
                key={index}
                className="badge bg-danger text-white me-2 mb-2 badgeclass"
              >
                #{tag?.title?.secondary}
              </span>
              </Link>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default MainContent;
