export const metaData = {
    metaDescription: {
      homepage: "Bring out the actor within you by registering at our acting auditions at TakeMyAudition. Our online acting auditions is your step towards a brighter future.",
      acting: "Read our latest acting blogs to learn more about your profession. At TakeMyAudition, you’ll find excellent acting tips that you can put into practice today!.",
      modelling: "Are you searching for modeling tips online? Visit TakeMyAudition’s to explore a range of fashion modelling articles relevant to your career.",
      performers: "Need acting tips to better your overall performance? At TakeMyAudition, you will be able to access variety of relevant acting performances blogs online.",
      photography: "When it comes to best photography tips, TakeMyAudition is here for you. Perfect your skills by reading our latest acting photography article online today!",

      style_check: "Are you looking for the latest acting style and techniques articles? Learn about the most popular acting style through our exclusive blogs at TakeMyAudition.",
      match_it: "Do you know the significant of colour in acting? Read our latest colour tops article at TakeMyAudition to learn about its importance in the acting industry.",
      footwear: "When it comes to relevant shoes tips, TakeMyAudition can be of help. Go through our website to read the best footwear article online. Take a look now!.",
      men: "Want to stay on top of the game in men’s fashion? Browse through our elaborate blogs at TakeMyAudition to find some excellent fashion & clothing tips for men.",
      face_care: "A simple skin care routine can make all the difference your face needs. Browse through our blogs at TakeMyAudition to find the best face care tips online.",

      hair_care: "Is hair care really important to you? At TakeMyAudition, get access to a variety of blogs on natural hair care to learn the most effective tips online.",
      make_up: "If you are in search of makeup tips in acting, TakeMyAudition is here to enlighten you. Read about the most effective makeup tips to perfect the art.",
      body_care: "The role of body care cannot be underestimated in acting. Learn about effective skin & beauty tips from experts only at TakeMyAudition’s latest blogs.",
      jewelry: "There are a lot of nuances related to fashion accessories for men in acting. Learn all about them in our fashion accessories article at TakeMyAudition.",

      
      bags: "Do you need some help while choosing a bag’s design for audition? Find the best tips for bags from experts, available only at TakeMyAudition. Take a look!.",
      watches: "Do you want to know which men’s watch style goes the best with outfits? Take a look at TakeMyAudition’s blogs to learn about the best watches’ tips online.",
      fragrances: "When it comes to great fragrance tips, TakeMyAudition is here for you. Learn everything about the best fragrance options only at our official blog section.",
      exercise: "Every actor should follow exercise tips to stay fit and healthy. At TakeMyAudition, find exercise tips article to supplement your current fitness regime.",


      nutrition: "Want to learn about the health benefits of various food groups? Read about food and its nutritive properties only at TakeMyAudition’s nutrition article.",
      supplements: "Don’t know which health supplements you should take to remain healthy? At TakeMyAudition, you can find the latest supplements tips given by experts online.",
      emotions: "When it comes to acting, your confidence level has a huge role to play. Learn how to balance and improve your emotions from our blogs at TakeMyAudition.",
      fashion_weeks: "Are you looking for fashion weeks updates online? Explore our blogs on TakeMyAudition to find some of the popular fashion weeks articles for important details.",

      rendevouz: "Do you want to learn about the rendezvous technique? Give our blogs at TakeMyAudition to learn everything about various rendezvous skills online today!.",
      classics: "Are you enthusiastic about classical dancing and music? Go through our elaborate blog section at TakeMyAudition to read our classical dancing article today!.",
      feedback: "Are you already a member of the TakeMyAudition family? By following a few simple steps, you can give your valuable feedback to help us improve our community.",
      "Disclaimer": "Do you want to learn about TakeMyAudition’s role in the acting community? Stay informed by reading our disclaimer and terms of service in detail. Click here.",

      
      advertise_with_us: "If you are looking for actors and models for a project, you can advertise with us at TakeMyAudition. Check out our website to find out all the details now!.",
      contact_us: "If you have a question or query, feel free to get in touch with us. Fill in your details and message at our official website to contact us without any hassle.",
      privacy_policy: "Are you concerned about your privacy? At TakeMyAudition, we’re extremely careful with the information we collect. Read our privacy policy to know more."
    
    },

    metaKeywords: {
        homepage: "actors audition, online acting auditions, acting auditions",
        acting: "latest acting blogs,latest acting articles , acting tips",
        modelling: "fashion modelling, fashion modelling articles , modelling tips",
        performers: "acting performances, acting tips, acting performances article",
        photography: "best photography tips, acting photography, acting photography article",
  
        style_check: " acting style, acting style techniques, most popular acting style",
        match_it: "colour in acting, colour tips, colour tips article",
        footwear: "best footwear, shoes tips, best footwear article ",
        men: "Mens Fashion, Fashion clothing, Clothing Tips",
        face_care: "Face care tips, Simple skin care, Face care article",
  
        hair_care: "hair care, hair care tips, natural hair care",
        make_up: "makeup tips, makeup tips in acting, makeup tips article",
        body_care: "body care, skin & beauty tips, body care article",
        jewelry: "fashion accessories, jewelry tips, fashion accessories article",
  
        
        bags: "Choosing a bags, Tips for bags, bags article",
        watches: "watches tips, men's watch style, men's watch article",
        fragrances: "great fragrance tips, best fragrance, best fragrance article",
        exercise: "exercise tips, healthy actor article, exercise tips aticle",
  
  
        nutrition: "Nutrition article, Nutrition for health, health benefits",
        supplements: "Health Supplements, online Health Supplements, supplements tips",
        emotions: "Improve Your emotions,  Confidence Level, emotions Atricle",
        fashion_weeks: "fashion weeks,  fashion technology, fashion weeks Articles",
  
        rendevouz: "rendezvous technique, rendezvous skills, rendezvous technique article",
        classics: "classical music, classical dancing article, classical dancing",
        feedback: "feedback",
        disclaimer: "disclaimer",
  
        
        advertise_with_us: "advertise with us",
        contact_us: "contact us",
        privacy_policy: "privacy policy"

    },

    metaTitle: {
        homepage: "Online Acting Auditions | Actors Auditions in India - Takemyaudition",
        acting: "Read Latest Acting Blogs & Articles | Acting Tips - Takemyaudition",
        modelling: "Read Article on Fashion Modelling | Modelling Tips in India - Takemyaudition",
        performers: "Best Acting Performances Article & Blogs | Acting Tips - Takemyaudition",
        photography: "Article on Acting Photography | Best Photography Tips on Takemyaudition",
  
        style_check: "Read Most Popular Acting Style and Techniques Article - Takemyaudition",
        match_it: "Read Article on Match it Colour in Acting | Colour Tips - Takemyaudition",
        footwear: "Read Best Footwear Article & Blog | Shoes Tips - Takemyaudition",
        men: "Read Mens Fashion Clothing Article | Clothing Tips - Takemyaudition",
        face_Ccre: "Read Face Care Tips Article & Blog | Simple Skin Care on Takemyaudition",
  
        hair_care: "Read Natural Hair Care Article & Blog | Hair Care Tips - Takemyaudition",
        make_up: "Read Article on Makeup Tips in Acting | Acting Makeup - Takemyaudition",
        body_care: "All Body Care Article & Blog | Skin & Beauty Tips - Takemyaudition",
        jewelry: "Best Fashion Accessories for Men Article | Jewelry Tips - Takemyaudition",
  
        
        bags: "Article on Choosing a Bags | Tips for Bags - Takemyaudition",
        watches: "Read Men's Watch Style Article & Blog | Watches Tips - Takemyaudition",
        fragrances: "Read Best Fragrance Article & Blog | Great Fragrance Tips - Takemyaudition",
        exercise: "Exercise Tips for the Healthy Actor Article | Takemyaudition",
  
  
        nutrition: "Read Nutrition for Health Article | Health Benefits - Takemyaudition",
        supplements: "Read Online Health Supplements Article | Supplements Tips - Takemyaudition",
        emotions: "Read Improve Your Emotions Atricle | Confidence Level - Takemyaudition",
        fashion_weeks: "Read Fashion-weeks Articles & Blog | Fashion Technology - Takemyaudition",
  
        rendevouz: "Read Rendezvous Technique Article | Rendezvous Skills - Takemyaudition",
        classics: "Read Classical Dancing Article | Classical Music - Takemyaudition",
        feedback: "Feedback - Takemyaudition",
        disclaimer: "Disclaimer - Takemyaudition",
  
        
        advertise_with_us: "Advertise With Us - Takemyaudition",
        contact_us: "Contact Us - Takemyaudition",
        privacy_policy: "Privacy Policy | Takemyaudition"

    }

  }

  
   