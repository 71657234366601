import React from "react";
import { Route } from "react-router-dom";
import { ROUTES } from "./constans";
import MainComponent from "../pages/mainComponent/MainComponent";
import RecommendedArticlesPages from "../pages/recommendedArticlesPage/RecomendedArticlePages";
import SubCategoryArticle from "../pages/subCategoryArticle/SubCategoryArticle";
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Otp from "../pages/Auth/otp/Otp";
import ForgotPassword from "../pages/Auth/forgotPassword/ForgotPassword";
import ResetPassword from "../pages/Auth/resetPassword/ResetPassword";
import FooterCommonPageLayout from "../footerPagesLayout/FooterCommonPageLayout";

/* Common Pages */


const commonRoutes = [
  <Route key="article" path={ROUTES.COMMON.ARTICLES} element={<MainComponent/>} />,
  // <Route key="articleLink" path={ROUTES.COMMON.ARTICLELINK} element={<MainComponent/>} />,
  <Route key="recommendedArticles" path={ROUTES.COMMON.RECOMMENDED_ARTICLES} element={<RecommendedArticlesPages/>} />
  ,
  <Route key="tagArticles" path={ROUTES.COMMON.TAG_ARTICLES} element={<RecommendedArticlesPages/>} />,
  <Route key="categoryArticles" path={ROUTES.COMMON.CATEGORY_ARTICLES} element={<SubCategoryArticle/>} />,

  // AUTH
  <Route key="Login" path={ROUTES.AUTH.LOGIN} element={<Login/>} />,
  <Route key="Signup" path={ROUTES.AUTH.SIGNUP} element={<SignUp/>} />,
  <Route key="Otp" path={ROUTES.AUTH.OTP} element={<Otp/>} />,
  <Route key="ForgotPassword" path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPassword/>} />,
  <Route key="ResetPassword" path={ROUTES.AUTH.RESET_PASSWORD} element={<ResetPassword/>} />,

  // FOOTER
  <Route key="PrivacyPolicy" path={ROUTES.FOOTER.PRIVACY_POLICY} element={<FooterCommonPageLayout/>} />,
  <Route key="Feedback" path={ROUTES.FOOTER.FEEDBACK} element={<FooterCommonPageLayout/>} />,

];

export default commonRoutes;